.filterStatusContainer,
.filterTypesContainer {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    width: 100%;
}

.filterStatusItem {
    width: fit-content;
    margin: 10px auto;
}

.disabled {
    // pointer-events: none;
    color: #aeaeae;
    //text-decoration: line-through;

    --fill-override: #aeaeae;

    // svg {
    //     display: none;
    // }
}

ion-popover {

    :host {
        --width: 620px;
    }

    .popover-content {
        --width: 80vw;
    }
}

.none {
    display: none;
}




.details {

    ion-col {

        border-right: 1px solid lightgray;

    }

    ion-col:nth-child(1) {
        padding-left: 0;
    }

    ion-col ion-item:last-child {
        --border-width: 0 !important;
    }
}

.addSystem {
    position: absolute;
    top: 0;
    left: 0;
}

.iconSize {
    width: 32px;
    height: 50px;
}

// .eventIcons {
// margin-top: -.75em;
// margin-bottom: -.75em;
// }

.iconContainer {
    display: flex;
    flex-direction: column;
}