.navbar {
    contain: none;
}

.left {
    flex-grow: 1;
}

.right {
    justify-content: right;
}

.tabButton {
    &[selected='true'] {
        font-weight: bolder;
        border-width: 2px;
        border-style: none;
        border-color: rgba(var(--ion-color-contrast), 0.7);
        border-top-style: solid;
    }
}

.desktop {

    .tabButton {
        font-size: 16px;

        &[selected='true'] {
            font-weight: bolder;
            border-width: 2px;
            border-style: none;
            border-color: rgba(var(--ion-color-contrast), 0.7);
            border-bottom-style: solid;
        }
    }

    .left {
        justify-content: start;
    }
}

.button {

    color: var(--ion-color-contrast);
    background-color: var(--ion-color-contrast);

    margin: 0 !important;
    height: 100% !important;

    --border-radius: 0 !important;

    * {
        padding: 0 10px 0 10px;
    }

}